import React, { useRef, useContext, useEffect } from "react"
import { Form, Formik } from "formik"
import moment from "moment"

import Dropzone from "./Dropzone"
import CartLayout from "../Layout/CartLayout"

import EpharmacyCart from "../Epharmacy/Cart"
import UploadGuidelines from "./UploadGuidelines"
import { AppContext } from "../../context/AppContext"
import DocumentsCardRow from "../Cart/DocumentsCard/DocumentsCardRow"
import { generateFormField } from "../Elements/Form/services/form"
import { prescriptionDateValidationSchema } from "../Epharmacy/utils/formData"

const MAX_FILE_SIZE_IN_BYTES = 5242880
const MAX_FILE_COUNT = 5

const Upload = ({ backRoute, nextRoute, flow, withCart = true, location }) => {
  const fileInputRef = useRef(null)
  const { dispatch, state } = useContext(AppContext)
  const { documents } = state

  const Cart = ({ callback, isPrescriptionDateFilled }) => (
    <EpharmacyCart
      open={{ documentsCard: true }}
      withCart={withCart}
      location={location}
      callback={callback}
      isPrescriptionDateFilled={isPrescriptionDateFilled}
    />
  )

  const handleFileChooser = () => {
    fileInputRef.current.click()
  }

  useEffect(() => {
    dispatch({ type: "GET_CONTEXT_FROM_SESSION" })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDeleteDocument = async (index) => {
    const documentsList = [...documents]
    documentsList.splice(index, 1)
    await dispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...state,
        documents: [...documentsList],
      },
    })
  }

  const dateFormField = {
    name: "prescriptionDate",
    type: "datev2",
    label: "Date on Prescription",
    isRequired: true,
    config: {
      range: {
        minDate: moment().subtract(120, "years").format("YYYY-MM-DD"),
        maxDate: moment().format("YYYY-MM-DD"),
      },
      // weekdays: true,
      birthday: true,
      keepHolidays: true,
    },
  }

  return (
    <Formik
      initialValues={state.epharmacy}
      validationSchema={prescriptionDateValidationSchema}
      validateOnMount
      onSubmit={(values, actions) => {
        actions.setSubmitting(true)
        dispatch({
          type: "SAVE_CONTEXT_TO_SESSION",
          payload: {
            ...state,
            epharmacy: {
              ...state.epharmacy,
              prescriptionDate: values.prescriptionDate,
            },
          },
        })
        actions.setSubmitting(false)
      }}
    >
      {({
        values,
        handleChange,
        setFieldValue,
        isValid,
        dirty,
        submitForm,
        isSubmitting,
      }) => (
        <CartLayout
          isCentered
          desktop={10}
          fullhd={8}
          cartContainer={
            <Cart
              isPrescriptionDateFilled={isValid}
              callback={() => {
                submitForm()
                // while (isSubmitting) {
                //   console.log("current submitting")
                // }
                // console.log("finished submitting", state)
              }}
            />
          }
          back={{
            label: "Back",
            link: backRoute,
            className: "is-hidden-desktop is-hidden-tablet",
          }}
          next={{
            label: "Next: Checkout",
            link: nextRoute,
            disabled: !state?.documents.length || !isValid,
            className: "is-hidden-desktop is-hidden-tablet",
            callback: () => submitForm(),
          }}
        >
          <UploadGuidelines />
          <div className="my-2 is-hidden-desktop">
            {documents.map((file, index) => (
              <DocumentsCardRow
                key={index}
                fileName={file.name}
                oldFileName={file.oldname}
                index={index}
                handleDeleteDocument={handleDeleteDocument}
                file={file}
              />
            ))}
          </div>
          <Dropzone
            fileInputRef={fileInputRef}
            handleFileChooser={handleFileChooser}
            maxByteSize={MAX_FILE_SIZE_IN_BYTES}
            maxFileCount={MAX_FILE_COUNT}
            docType="RX"
          />
          <Form>
            {generateFormField({
              formField: dateFormField,
              values,
              onChange: handleChange,
            })}
          </Form>
        </CartLayout>
      )}
    </Formik>
  )
}

export default Upload
